

window.addEventListener("load", function(){
      window.cookieconsent.initialise({
        "palette": {
          "popup": {
            "background": "#1995dc"
          },
          "button": {
            "background": "#ffffff"
          }
        },
        "theme": "classic",
        
        "content": {
                "message":  "Vi använder cookies för att göra din upplevelse av vår sajt så bra som möjligt.",
                
                "dismiss": "Jag förstår!",
                "link": "Läs mer",
                "href": "//regnradar.se/privacy"
        }
        
      })});
      
      
      
      
                