// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//require("@rails/ujs").start()
//require("turbolinks").start()
//require("channels")
require("leaflet/leaflet")
require("magnific/jquery.magnific-popup.min")
window.Cookies = require("js/cookie.min")

require("js/jquery.mmenu.min")
require("js/radar")
require("serviceworker-companion")
require("js/cookieconsent.min")
require("js/cookieconsent.init")

//require ("@fortawesome/fontawesome-free/css/all")

//import "@fortawesome/fontawesome-free/css/all"



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
