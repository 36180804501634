if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/serviceworker.js', { scope: './' })
    .then(function(reg) {
      console.log('[Companion]', 'Service worker registered!');
    });
}


var deferredPrompt;
document.addEventListener("DOMContentLoaded", function() {
  const appContainer = document.getElementById("appContainer");
  const beforeInstallPrompt = (event) => {
     deferredPrompt = event;
     appContainer.classList.remove('hidden');
  };
  window.addEventListener('beforeinstallprompt', beforeInstallPrompt, true);

  if(appContainer) {
    appContainer.addEventListener("click", (mouseEvent) => {
    if(deferredPrompt)
       deferredPrompt.prompt();  
    });
  }

});
